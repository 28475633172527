<template>
  <ShadowCard
    :param="{
      name: 'New Position',
      addRowRef: 'positionsForm',
    }"
  >
    <PositionsForm
      ref="positionsForm"
      :fields="fields"
      :singleRow="singleRow"
    />
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import PositionsForm from "@/functionalComponents/PositionsForm.vue";
export default {
  name: "positions",
  components: {
    PositionsForm,
    ShadowCard,
  },
  data() {
    return {
      singleRow: {
        date: new Date().toISOString().substr(0, 10),
        portfolio_id: "",
        trade: "BUY",
        asset_id: "",
        principal: "",
        fx_rate: "",
        price: "",
        trans: "false",
      },
      fields: [
        {
          field: "date",
          editable: "date",
          label: "Date",
          tdClass: "align-bottom text-center",
          thClass: "text-center",
          type: "text",
          width: "178px",
        },

        {
          field: "portfolio_id",
          label: "Portfolio ID",
          thClass: "text-center",
          tdClass: "align-bottom text-left",
          editable: "portfolioSelect",
          width: "17%",
        },

        {
          field: "asset_id",
          label: "Asset ID",
          thClass: "text-center",
          tdClass: "align-bottom text-left",
          editable: "assetSelect",
          width: "25%",
        },
        {
          field: "principal",
          label: "Principal",
          thClass: "text-center",
          tdClass: "align-bottom text-right",
          editable: "number",
          width: "10%",
        },
        {
          field: "fx_rate",
          label: "FX Rate",
          thClass: "text-center",
          tdClass: "align-bottom text-right",
          editable: "number",
          width: "10%",
        },
        {
          field: "price",
          label: "Price",
          thClass: "text-center",
          tdClass: "align-bottom text-right",
          editable: "number",
          width: "10%",
        },
        {
          field: "trans",
          label: "Trans",
          thClass: "text-center",
          tdClass: "align-bottom text-center align-middle",
          editable: "checkbox",
        },
        {
          field: "Actions",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center align-middle",
        },
      ],
    };
  },
};
</script>

<style></style>
