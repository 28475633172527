<template>
  <div class="positionsForm">
    <vue-good-table
      class="mb-4"
      ref="positionsTable"
      :columns="fields"
      :rows="rows"
      :row-style-class="rowStyleClassFn"
      styleClass="vgt-table whiteBg-table"
      :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
      :sort-options="{
        enabled: false,
      }">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.row.status == 'edited'">
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-else-if="props.column.editable == 'date'">
          <div>
            <input
              v-model="props.row[props.column.field]"
              type="date"
              class="form-control" />
          </div>
        </span>
        <span
          v-else-if="props.column.editable == 'portfolioSelect'"
          class="editableCell">
          <v-select
            :options="optionsPortfolios"
            v-model="props.row[props.column.field]"
            class="editSelect text-center p-0 inputNoBorder"
            style="text-align: 'center'"
            placeholder="Select Portfolio"></v-select>
        </span>
        <span
          v-else-if="props.column.editable == 'tradeSelect'"
          class="editableCell">
          <v-select
            :options="optionsTrade"
            v-model="props.row[props.column.field]"
            class="editSelect text-center p-0 inputNoBorder"></v-select>
        </span>
        <span
          v-else-if="props.column.editable == 'assetSelect'"
          class="editableCell">
          <v-select
            class="text-center p-0 inputNoBorder"
            @input="getFxRate"
            :options="securities"
            label="name"
            v-model="props.row[props.column.field]"
            :filter-by="securityFilter"
            :selectOnTab="true"
            placeholder="Select Asset">
            <template #selected-option="{ name, bbg_ticker }">
              <span class="overflowSelect">{{ name }} ({{ bbg_ticker }})</span>
            </template>
            <template #option="{ name, bbg_ticker }">
              <span>{{ name }} ({{ bbg_ticker }})</span>
            </template>
          </v-select>
        </span>
        <span
          v-else-if="props.column.editable == 'number'"
          class="editableCell">
          <b-form-input
            v-model="props.row[props.column.field]"
            class="text-right"
            v-cleave="{
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
              numeralDecimalScale: 6,
              ...(props.column.field === 'price'
                ? numericFormatWithPrefix()
                : {}),
            }"></b-form-input>
        </span>
        <span v-else-if="props.column.editable == 'checkbox'">
          <b-form-checkbox
            :id="props.row.uuid + 'checkboxID'"
            v-model="props.row[props.column.field]"
            name="checkbox-1"
            value="true"
            unchecked-value="false">
          </b-form-checkbox>
        </span>
        <span
          class="actions"
          inline
          flex
          v-else-if="props.column.field == 'Actions'">
          <DeleteButton @delete="deleteFn(props)" />
        </span>
      </template>
    </vue-good-table>
    <div class="row justify-content-between">
      <BackButton />
      <div class="col-8 text-right">
        <ResetButton @reset-form="reset()" />
        <SubmitButton
          :ShowSubmitButton="showSubmitButton"
          :ShowOverlay="showOverlay"
          @submit-form="submit()" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPortfolios,
  getSecurities,
  createPortfolioPositions,
  getPortfolioPerformance,
  getPortfolioDetail,
  getCurrencyByID,
} from "@/services/api.js";
import { v4 as uuidv4 } from "uuid";

import BackButton from "@/components/buttons/backButton.vue";
import ResetButton from "@/components/buttons/resetButton.vue";
import SubmitButton from "@/components/buttons/submitButton.vue";
import DeleteButton from "@/components/buttons/deleteButton.vue";

export default {
  name: "PositionsForm",
  components: { BackButton, ResetButton, SubmitButton, DeleteButton },
  props: {
    fields: {
      type: Array,
    },
    singleRow: {
      type: Object,
    },
  },
  data() {
    return {
      rows: [1],
      drop: [],
      optionsPortfolios: [],
      optionsTrade: [],
      securities: [],
      showSubmitButton: false,
      showOverlay: false,
    };
  },
  methods: {
    numericFormatWithPrefix() {
      return {
        numeral: true,
        prefix: "$",
        numeralThousandsGroupStyle: "thousand",
        numeralDecimalScale: 6,
      };
    },

    deleteFn(props) {
      let idToDelete = props.row.uuid;
      this.rows = this.rows.filter((element) => element.uuid !== idToDelete);
    },
    reset() {
      this.fillArray();
      this.showSubmitButton = false;
    },

    filterNumber(value) {
      // Remove sign, comma, and dot from the value
      const filteredValue = value.replace(/[^\d.-]/g, "");
      return filteredValue;
    },
    submit() {
      if (this.$refs["positionsTable"].selectedRows.length) {
        //busco que ids se editaron
        let idsToEdit = [
          ...this.$refs["positionsTable"].selectedRows.map((row) => row.uuid),
        ];
        //agarro la data de las rows actualizadas
        let rowsToSend = [
          ...this.$refs["positionsTable"].selectedRows.map((row) =>
            this.clearSelectedRow(row)
          ),
        ];

        //actualizo las rows que se seleccionaron por las nuevas
        this.rows = this.rows.map(
          (obj) =>
            rowsToSend.find((rowUpdated) => rowUpdated.uuid === obj.uuid) || obj
        );
        //tengo la rows actualizadas con status:edited (newRows) ,  si la respuesta esta ok reemplazo rows por newRows

        rowsToSend.forEach((element) => {
          if (element.price) {
            // Verifico de que 'price' no sea nulo antes de la modificación
            // y si no es nulo lo limpio
            element.price = this.filterNumber(element.price);
          }
          // Asegurarse de que 'asset_id' no sea nulo antes de la modificación
          if (element.asset_id) {
            element.asset_id = element.asset_id.asset_id;
          }
        });

        // Crear newrows después de las modificaciones en rowsToSend
        const newrows = this.rows.map(({ ...element }) => {
          const isEdited = idsToEdit.includes(element.uuid);
          if (isEdited) {
            element.status = "edited";
            element.asset_id = element.asset_id.name;
          }
          return element;
        });

        let objectToPost = { positions: newrows };
        this.showOverlay = true;
        createPortfolioPositions("positions", objectToPost).then((response) => {
          if (response.status == 201) {
            // Este if comprueba el status de la response si es igual a 201
            this.rows = newrows;
            this.makeToast(
              "success",
              response.status,
              "Row created successfully"
            ); // Este toast se ejecuta si la response es 201 Created
            this.showSubmitButton = true;
            this.showOverlay = false;

            let selectedPortfolio = this.$store.state.selectedPortfolio;

            if (selectedPortfolio) {
              for (let position of objectToPost.positions) {
                if (position.portfolio_id === selectedPortfolio) {
                  // Limpio el cuadro de performance
                  this.$store.commit("SET_PORTFOLIOPERFORMANCE", {});
                  // Hago el request al api de performance
                  getPortfolioPerformance(selectedPortfolio).then((res) => {
                    console.log(res);
                    let payload = res.data.data;
                    let performanceData = {
                      // We add a nullish coalescing operator because some fields are not always present un the API response.
                      BalanceDate: payload.balance_date ?? null,
                      InitialNetWorth: payload.balance ?? null,
                      TransfersInOut: payload.transfers ?? null,
                      CurrentNetWorth: payload.current_portfolio ?? null,
                      ProfitAndLossInCapital: payload.pnl ?? null,
                      TimeWeightedReturn: payload.hpr ?? null,
                      MoneyWeightedReturn: payload.mwr ?? null,
                      AnnualizedReturn: payload.ar ?? null,
                    };
                    // Guardo en el store los datos que me trajo el api de performance
                    this.$store.commit(
                      "SET_PORTFOLIOPERFORMANCE",
                      performanceData
                    );
                  });
                  getPortfolioDetail(selectedPortfolio).then((res) => {
                    // Refactor to getPortfolioPositionsByAssetId
                    let payload = res.data.data;
                    payload.map((element) => (element.uuid = uuidv4()));
                    this.$store.commit("SET_PORTFOLIODATA", payload); // Refator PORTFOLIO_POSITIONS
                  });

                  break;
                }
              }
            }
          } else {
            this.makeToast("error", response.status, "Error creating row"); // Este toast se ejecuta si la response es 201 Created
          }
        });
      }
    },
    makeToast(variant = null, status, text) {
      this.$bvToast.toast([text], {
        title: `Code: ${status || "default"}`,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },

    clearSelectedRow(row) {
      let originalRow = row;
      const { vgt_id, vgtSelected, originalIndex, ...rest } = originalRow; //  {propertyToDelete,propertyToDelete2, ..restOfProperties }
      let rowRestOfKeys = rest;

      return rowRestOfKeys;
    },
    fillArray() {
      let row = this.singleRow;
      row.uuid = uuidv4();
      this.rows = [row];
    },
    addRow() {
      this.rows = this.$refs["positionsTable"].processedRows[0].children;
      let newRow = { ...this.singleRow };
      newRow.uuid = uuidv4();
      this.rows.push(newRow);
    },
    getPortfolios() {
      getPortfolios().then(
        (response) =>
          (this.optionsPortfolios = response.data.data.map(
            (item) => item.portfolio_id
          ))
      );
    },
    getSecurities() {
      getSecurities().then((response) => (this.securities = response[0]));
    },

    getFxRate(value) {
      getCurrencyByID(value.currency_id).then((response) => {
        let fxRate = response.data.data.px_last;
        console.log("px_last:", fxRate);
      });
    },

    rowStyleClassFn(row) {
      return row.status == "edited" ? "editedRow" : "compactRow";
    },
    securityFilter: (option, label, search) => {
      //by default in an array of objects, vue-select will look for a label "key" (only one),the custom filter is used to determine if the provided option matchs the current search text, in order to determine if the option should be displayed.
      let temp = search.toLowerCase();
      return (
        option.figi?.toLowerCase().indexOf(temp) > -1 ||
        option.isin?.toLowerCase().indexOf(temp) > -1 ||
        option.bbg_ticker.toLowerCase().indexOf(temp) > -1 ||
        option.name.toLowerCase().indexOf(temp) > -1
      );
    },
  },
  created() {
    this.getPortfolios();
    this.getSecurities();
    this.positions.length == 0
      ? this.fillArray()
      : (this.rows = this.positions); // if store.rows.legth = 0 => fill array  : rows = store.rows

    console.log(this.fields);
  },
  computed: {
    positions() {
      return this.$store.state.positions;
    },
  },
  beforeDestroy() {
    this.$store.commit("SET_POSITIONS", this.rows);
  },
};
</script>

<style>
.positionsForm > .vgt-wrap > .vgt-inner-wrap > .vgt-responsive {
  padding-bottom: 32vh;
}

table.whiteBg-table {
  border: 0 solid;
}
table.whiteBg-table td {
  border: 0 solid;
}
table.whiteBg-table thead th {
  background: rgba(253, 253, 253, 0);
  border-bottom: 0;
}

.editedRow {
  background-color: #c3ecc336;
}
.overflowSelect {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table.whiteBg-table .vgt-checkbox-col {
  background: rgba(253, 253, 253, 0);
  border: 0;
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 85%;
  vertical-align: middle;
}
</style>
